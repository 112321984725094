import React, { useEffect, useState, useCallback, useRef } from "react";
import { graphql, Link } from "gatsby";
import { Container, Spinner } from "react-bootstrap";

// Type imports
import { ICheckoutProps } from "../pageHelpers/Checkout/CheckoutProps";
import { Constants, GATSBY_MOBILE_APP } from "../@types/Constants";

// Component imports
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import CheckoutFinish from "../components/common/CheckoutFinish/CheckoutFinish";
import CheckoutGuestDetails from "../components/common/CheckoutGuestDetails/CheckoutGuestDetails";
import ErrorMessageAlert from "../components/common/ErrorMessageAlert/ErrorMessageAlert";
import {
  Accomendations,
  CheckoutAlert,
  HiddenContainer,
  buildCartStackParams,
  checkSelectRoom,
  resetRoomPricing,
  getBreadCrumbStep,
  postRecentSearchIfNecessary,
  checkDateUpdatesOnly,
} from "../pageHelpers/Checkout/CheckoutHelpers";

// Hook imports
import { useAppDispatch, useAppSelector } from "../hooks";
import useDebounce from "../hooks/useDebounce";
import { useWebFramed } from "../hooks/useWebFramed";
import { useCheckoutRefreshAvailability } from "../hooks/useCheckoutRefreshAvailability";

// Redux actions
import { setCheckout } from "../redux/slices/Checkout/checkout";
import { setInitiateReservationResponse } from "../redux/slices/Checkout/initiateReservation";

// Service and utility imports
import {
  createInitiateReservationPayload,
  initiateReservation,
  cancelInitiatedReservation,
} from "../services/crs";
import { isEmptyObject } from "../services/helpers";
import { convertArrayToObject } from "../utils/helpers";
import useInitializeCheckout from "../hooks/useInitializeCheckout";

import { useCheckoutQuery } from "../pageHelpers/Checkout/CheckoutQuery";
import useUpdateCheckoutRooms from "../hooks/useUpdateCheckoutRooms";

const Checkout: React.FC<ICheckoutProps> = (props) => {
  const data = useCheckoutQuery();
  const page = data.page;
  const location = props.location || {};
  const dispatch = useAppDispatch();
  const isWebFramed = useWebFramed();
  const STEPS = ["empty", "select_room", "guest_details", "finish"];
  const unlockBannerShow = page ? page.field_show_unlock_banner : false;
  const _site = data.allRlhsite.edges[0].node;

  const checkout = useAppSelector((state: any) => state.checkout);
  const initiateReservationResponse = useAppSelector(
    (state: any) => state.initiateReservationResponse
  );
  const search = useAppSelector((state: any) => state.search);
  const recentSearch = useAppSelector((state: any) => state.recentSearch);
  const crmProfile = useAppSelector((state: any) => state.member.crmProfile);
  const crmProfileRef = useRef(crmProfile);
  const checkoutRef = useRef(checkout);
  const recentSearchRef = useRef(recentSearch);

  const [checkoutReady, setCheckoutReady] = useState(false);
  const [step, setStep] = useState(checkout ? checkout.Step : "");
  const [hotel, setHotel] = useState(null);
  const [_brandCode, setBrandCode] = useState(null);
  const [currentRoomIndex, setCurrentRoomIndex] = useState(0);
  const [cartstackParams, setCartstackParams] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState<any>("");
  const [step1page, setStep1page] = useState(false);
  const [stayOnGuestDetails, setStayOnGuestDetails] = useState(false);
  const [prevRoomCode, setPrevRoomCode] = useState(null);

  const [loadingRates] = useCheckoutRefreshAvailability(
    prevRoomCode,
    stayOnGuestDetails,
    setStep,
    setStayOnGuestDetails
  );

  useInitializeCheckout(
    search,
    step,
    STEPS,
    isWebFramed,
    location,
    checkout,
    setStep,
    setStep1page,
    setHotel,
    setCurrentRoomIndex,
    setCartstackParams,
    setBrandCode,
    setCheckoutReady
  );

  useUpdateCheckoutRooms(
    checkoutReady,
    search,
    checkout,
    location,
    setCartstackParams
  );

  const breadCrumbStep = getBreadCrumbStep(STEPS, step);

  const handleClearError = () => {
    setErrorMsg("");
    setShowError(false);
  };

  const handleSetCurrentRoomIndex = (index: number) => {
    setCurrentRoomIndex(index);
  };

  const handleReservationSubmitError = (message: any) => {
    const errorMetaData = `Hotel-${checkout.HotelCode}`;
    const errorMsg = (
      <ErrorMessageAlert
        errorType={Constants.ERRORSTYPE.RESERVATION}
        errorSubType={Constants.ERRORSSUBTYPE.RESRVATION.CREATE}
        message={`${message} -- ${errorMetaData}`}
      >
        <span
          id="reservation-submit-error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </ErrorMessageAlert>
    );
    setErrorMsg(errorMsg);
    setShowError(true);
    const element = document.getElementById("reservation-submit-error");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleGoToSelectRoomStep = () => {
    setStep(STEPS[1]); //'select_room');
  };

  const handleGoToGuestDetailsStep = () => {
    setStep1page(false);
    setStep(STEPS[2]);
  };

  const handleGoToFinishStep = (updatedCheckout: any) => {
    const roomIds = Object.keys(updatedCheckout.Rooms);
    const initiatePayload = createInitiateReservationPayload(
      roomIds,
      updatedCheckout,
      crmProfile?.profileType || "Bronze"
    );
    if (
      initiateReservationResponse &&
      initiateReservationResponse?.reservationTransactionId
    ) {
      // Cancelling the previous initiated reservation
      const cancelResponse = cancelInitiatedReservation(
        initiateReservationResponse?.reservationTransactionId
      );
    }
    (async () => {
      try {
        const reservationResponse = await initiateReservation(initiatePayload);
        const reservationResponses = {
          roomIds: roomIds,
          reservationTransactionId:
            reservationResponse?.reservationTransactionId || "",
        };
        dispatch(setInitiateReservationResponse(reservationResponses));
      } catch (error) {
        console.error("Error making reservations:", error.message);
      }
    })();

    setStep(STEPS[3]);
  };

  const handleOnRoomsLoad = (roomsRates: any) => {
    const isDiscountInSearch = search.groupCode || search.promotionCode;
    let offerAvailable = false;
    if (isDiscountInSearch) {
      offerAvailable =
        roomsRates && roomsRates.length && roomsRates[0].FromRatePromotional;
      const discountType = search.promotionCode ? "Promo code" : "Group code";
      if (!offerAvailable && !isWebFramed) {
        const errorMsg: any = (
          <>
            Your {discountType} <strong>{isDiscountInSearch}</strong> is
            unavailable for the selected dates. We are showing lowest available
            rates.
          </>
        );
        setErrorMsg(errorMsg);
        setShowError(true);
      }
    }
  };

  const handleSelectedRoomsSoldOut = (updatedCheckoutRooms: any) => {
    const errorMsg = (
      <>
        Selected rooms are no longer available for current search.
        <br />
        Please re-select rooms below to proceed with your booking.
      </>
    );
    setErrorMsg(errorMsg);
    setShowError(true);
    dispatch(
      setCheckout({
        ...checkout,
        Rooms: convertArrayToObject(updatedCheckoutRooms, "id"),
      })
    );
    setStep(STEPS[1]); //'select_room');
  };

  // Watch for checkout room updates.
  useEffect(() => {
    if (checkoutReady && !isEmptyObject(checkout)) {
      const selectRoomIndex =
        checkout.Rooms && checkSelectRoom(Object.values(checkout.Rooms));
      if (selectRoomIndex !== null) {
        setCurrentRoomIndex(selectRoomIndex);
        setStep(STEPS[1]); //'select_room');
      } else if (step !== STEPS[3]) {
        //finish state
        setStep(STEPS[2]); //'guest_details';
        setStep1page(false);
      }
    }
  }, [checkoutReady, checkout, search.rooms]);

  // Follow search date updates to reset checkout.
  useEffect(() => {
    let didCancel = false;
    handleClearError();
    if (checkoutReady) {
      let resetRooms = false;
      // If dates changed, reselect all rooms.
      if (
        search.checkin !== checkout.Start ||
        search.checkout !== checkout.End
      ) {
        resetRooms = true;
      } else if (search.discount !== checkout.discount) {
        resetRooms = true;
      } else if (search.promotionCode !== checkout.promotionCode) {
        resetRooms = true;
      } else if (search.groupCode !== checkout.groupCode) {
        resetRooms = true;
      }
      if (resetRooms && checkout.Rooms) {
        const resetRoomsArray = resetRoomPricing(Object.values(checkout.Rooms));
        const newCheckout = {
          ...checkout,
          Start: search.checkin,
          End: search.checkout,
          discount: search.discount,
          promotionCode: search.promotionCode,
          groupCode: search.groupCode,
          Rooms: convertArrayToObject(resetRoomsArray, "id"),
          isResubmitted: false,
        };
        if (!didCancel) {
          dispatch(setCheckout(newCheckout));
          setStep(STEPS[1]); //'select_room');
          setCartstackParams(
            buildCartStackParams(
              checkout.HotelCode,
              search.checkin,
              search.checkout,
              resetRoomsArray,
              search.discount,
              search.promotionCode
            )
          );
        }
      }
    }
    return () => {
      didCancel = true;
    };
  }, [
    checkoutReady,
    search.checkin,
    search.checkout,
    search.discount,
    search.promotionCode,
    search.groupCode,
  ]);

  useEffect(() => {
    if (step === "empty" || (checkoutReady && hotel === null)) {
      setShowError(true);
      const errorMsg = (
        <ErrorMessageAlert
          errorType={Constants.ERRORSTYPE.CHECKOUT}
          errorSubType={Constants.ERRORSSUBTYPE.CHECKOUT.HOTELSEARCH}
          message={`Please search for a hotel`}
        >
          <h2 className="text-center">Please search for a hotel.</h2>
          <Link to="/hotel-search">Search</Link>
        </ErrorMessageAlert>
      );
      setErrorMsg(errorMsg);
    }
  }, [step, checkoutReady, hotel]);

  useEffect(() => {
    crmProfileRef.current = crmProfile;
    checkoutRef.current = checkout;
    recentSearchRef.current = recentSearch;
  }, [crmProfile, checkout, recentSearch]);

  useEffect(() => {
    let didCancel = false;
    const newCheckout = {
      ...checkout,
      Step: step,
    };
    !didCancel && checkout.Step != step && dispatch(setCheckout(newCheckout));
    typeof window !== "undefined" && window.scrollTo(0, 0);
    return () => {
      didCancel = true;
    };
  }, [step]);

  const debouncedPostRecentSearch = useCallback(() => {
    (async () => {
      try {
        await postRecentSearchIfNecessary(
          crmProfileRef.current,
          checkoutRef.current,
          search,
          recentSearchRef.current,
          isWebFramed,
          dispatch
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  }, []);

  const dependencies: any = [search]; // The dependencies array to be passed to the useDebounce hook

  // The useDebounce hook to delay the execution of postRecentSearchIfNecessary function by 5000ms
  useDebounce(debouncedPostRecentSearch, 5000, dependencies);

  useEffect(() => {
    if (step === STEPS[3]) {
      window.history.pushState(
        { step: STEPS[3] },
        "",
        window.location.pathname
      );
    }
    const handlePopState = (event) => {
      if (event.state && event.state.step === STEPS[3]) {
        setStep(STEPS[2]);
        window.removeEventListener("popstate", handlePopState);
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [step, setStep, STEPS]);

  useEffect(() => {
    const resultUpdate = checkDateUpdatesOnly(search.previousState, search);

    const getRoomCode = (data) => {
      const keys = Object.keys(data);
      if (keys.length > 0) {
        const firstKey = keys[0];
        if (data[firstKey] && data[firstKey].room) {
          return data[firstKey].room.code;
        }
      }
      return null;
    };

    if (
      resultUpdate &&
      checkout.Rooms &&
      search?.rooms?.length < 2 &&
      step === STEPS[2]
    ) {
      const roomCode = getRoomCode(checkout.Rooms);
      setPrevRoomCode(roomCode);
      setStayOnGuestDetails(true);
    } else {
      setStayOnGuestDetails(false);
    }
  }, [search]);

  if (loadingRates) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" />
      </div>
    );
  }
  if (!checkoutReady && isWebFramed) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <Layout
      {...{ site: _site }}
      searchBreadCrumbStep={breadCrumbStep}
      hotel={hotel}
      showLogo
      isHotelPage
      showMerchandisingBlock={false}
      handleGoToGuestDetailsStep={handleGoToGuestDetailsStep}
    >
      <SEO title="Checkout" location={props.location} koddiTitle="Checkout" />
      <HiddenContainer
        cartstackParams={cartstackParams}
        hotel={hotel}
        unlockBannerShow={unlockBannerShow}
      ></HiddenContainer>
      <Container className="pt-5 pb-5" fluid="sm">
        {showError && errorMsg && step !== "finish" && (
          <CheckoutAlert handleClearError={handleClearError}>
            {errorMsg}
          </CheckoutAlert>
        )}
        {checkoutReady && step === "select_room" && hotel !== null && (
          <Accomendations
            hotel={hotel}
            checkout={checkout}
            handleClearError={handleClearError}
            currentRoomIndex={currentRoomIndex}
            handleSetCurrentRoomIndex={handleSetCurrentRoomIndex}
            handleOnRoomsLoad={handleOnRoomsLoad}
            step={step}
            step1page={step1page}
          ></Accomendations>
        )}
        {checkoutReady && step === "guest_details" && hotel !== null && (
          <CheckoutGuestDetails
            checkin={checkout.Start}
            checkout={checkout.End}
            rooms={checkout.Rooms}
            hotel={hotel}
            index={currentRoomIndex}
            location={location}
            checkoutStep={step}
            onBackClick={handleGoToSelectRoomStep}
            onNextClick={handleGoToFinishStep}
            stayOnPage={stayOnGuestDetails}
          />
        )}
        {checkoutReady && step === "finish" && hotel !== null && (
          <CheckoutFinish
            hotel={hotel}
            location={location}
            rooms={checkout.Rooms}
            checkoutStep={step}
            onBackClick={handleGoToGuestDetailsStep}
            onSelectedRoomsSoldOut={handleSelectedRoomsSoldOut}
            onError={handleReservationSubmitError}
            clearError={handleClearError}
            errorMsg={errorMsg}
            showError={showError}
            handleClearError={handleClearError}
          />
        )}
      </Container>
    </Layout>
  );
};
export default Checkout;
